import axios from 'axios';
import { wmsheader } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const getCalendarEvents = (type, day, month, year) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/settings/calendar_events?type=${type}${day !== null && typeof day !== 'undefined' ? `&day=${day}` : ''}${month !== null && typeof month !== 'undefined' ? `&month=${month}` : ''}${year !== null && typeof year !== 'undefined' ? `&year=${year}` : ''}`,
  headers: wmsheader(),
});

export const createCalendarEvent = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/settings/calendar_events`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const saveCalendarEvent = (id, dataToSubmit) => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/admin/settings/calendar_events/${id}`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const deleteCalendarEvent = id => axios({
  method: 'DELETE',
  url: `${SITE_SERVER}/admin/settings/calendar_events/${id}`,
  headers: wmsheader(),
});

export const getClients = (status, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/clients?status=${status}&limit=${limit}&page=${page}`,
  headers: wmsheader(),
});

export const getAdminUsers = (limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/users?limit=${limit}&page=${page}`,
  headers: wmsheader(),
});

export const getAdminUserDetail = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/users/${id}`,
  headers: wmsheader(),
});

export const addAdminUser = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/users`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const updateAdminUser = (id, dataToSubmit) => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/admin/users/${id}`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const deleteAdminUser = id => axios({
  method: 'DELETE',
  url: `${SITE_SERVER}/admin/users/${id}`,
  headers: wmsheader(),
});

export const activateClient = (id, dataToSubmit) => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/clients/${id}/activate`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const deactivateClient = id => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/clients/${id}/deactivate`,
  headers: wmsheader(),
});

export const getClientDetails = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/clients/${id}`,
  headers: wmsheader(),
});

export const updateClientPassword = (id, dataToSubmit) => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/admin/clients/${id}/user/password`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const refreshApi = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/refreshtoken`,
  headers: wmsheader(),
});
