import {
  SET_STAFF_DATA,
  RESET_STAFF_DATA,
  SET_ACCOUNT_DATA,
  RESET_ACCOUNT_DATA,
} from './types';

const initialState = {
  list: [],
  account: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_STAFF_DATA: {
      return { ...state, list: action.payload.staff };
    }
    case RESET_STAFF_DATA: {
      return { ...initialState };
    }
    case SET_ACCOUNT_DATA: {
      return { ...state, account: action.payload.account };
    }
    case RESET_ACCOUNT_DATA: {
      return { ...state, account: action.payload.account };
    }
    default: return state;
  }
};

export default reducer;
