import {
  SET_DASHBOARD_DATA,
  SET_CLIENTS_DATA,
  SET_ORDER_PENDING_SUMMARY_DATA,
  SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA,
  SET_PURCHASE_ORDER_DATA,
  SET_RECENT_SUPPORT_MSG,
  SET_CLIENT_ID_DATA,
  SET_GRAPH_CLIENT_ID_DATA,
  SET_SEARCH_CLIENT_DATA,
  RESET_SEARCH_CLIENT_DATA,
} from './types';

const initialState = {
  dashboard: {},
  clients: {},
  ordersummary: {},
  ormonthlydashboard: {},
  purchaseorder: {},
  supportmsg: {},
  clientId: 0,
  graphClientId: 0,
  seachClientArray: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA: {
      return { ...state, dashboard: action.payload.dashboard };
    }
    case SET_CLIENTS_DATA: {
      return { ...state, clients: action.payload.clients };
    }
    case SET_ORDER_PENDING_SUMMARY_DATA: {
      return { ...state, ordersummary: action.payload.ordersummary };
    }
    case SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA: {
      return { ...state, ormonthlydashboard: action.payload.ormonthlydashboard };
    }
    case SET_PURCHASE_ORDER_DATA: {
      return { ...state, purchaseorder: action.payload.purchaseorder };
    }
    case SET_RECENT_SUPPORT_MSG: {
      return { ...state, supportmsg: action.payload.supportmsg };
    }
    case SET_CLIENT_ID_DATA: {
      return { ...state, clientId: parseInt(action.payload.clientId, 10) };
    }
    case SET_GRAPH_CLIENT_ID_DATA: {
      return { ...state, graphClientId: parseInt(action.payload.graphClientId, 10) };
    }
    case SET_SEARCH_CLIENT_DATA: {
      return { ...state, seachClientArray: action.payload.seachClientArray };
    }
    case RESET_SEARCH_CLIENT_DATA: {
      return { ...state, seachClientArray: [] };
    }
    default: return state;
  }
};

export default reducer;
