import { makeRequest, METHODS } from '@entando/apimanager';
import { getNotificationData, getNotificationUnreadData } from 'mocks/notification';
import axios from 'axios';
import { wmsheader } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const notifList = () => (
  makeRequest({
    uri: '/api/notifications',
    method: METHODS.GET,
    mockResponse: getNotificationData(),
    useAuthentication: false,
  })
);

export const notifUnread = () => (
  makeRequest({
    uri: '/api/notifications/total_unread',
    method: METHODS.GET,
    mockResponse: getNotificationUnreadData(),
    useAuthentication: false,
  })
);

/* export const notifList = (limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/notifications?limit=${limit}&page=${page}`,
  headers: wmsheader(),
}); */

export const notifRead = (id, dataToSubmit = {}) => axios({
  method: 'POST',
  url: `${SITE_SERVER}/notifications/read/${id}`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const notifListAdmin = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/notifications`,
  headers: wmsheader(),
});

/* export const notifUnread = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/notifications/total_unread`,
  headers: wmsheader(),
}); */

export const notificationSettings = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/settings/notification`,
  headers: wmsheader(),
});

export const updateNotifSettings = dataToSubmit => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/settings/notification`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const resetNotification = () => axios({
  method: 'POST',
  url: `${SITE_SERVER}/notifications/reset_count`,
  headers: wmsheader(),
});
