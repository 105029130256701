/* eslint-disable max-len */
/* eslint-disable no-console */
import {
  getDashboard,
  getClients,
  getOrderReportsMonthly,
  getOrderPendingSummary,
  getPurchaseOrder,
  getRecentSupoortMsg,
  searchClient,
} from 'api/reports';
import { showActionLoading, hideActionLoading } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_DASHBOARD_DATA,
  SET_CLIENTS_DATA,
  SET_ORDER_PENDING_SUMMARY_DATA,
  SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA,
  SET_PURCHASE_ORDER_DATA,
  SET_RECENT_SUPPORT_MSG,
  SET_CLIENT_ID_DATA,
  SET_GRAPH_CLIENT_ID_DATA,
  SET_SEARCH_CLIENT_DATA,
  RESET_SEARCH_CLIENT_DATA,
} from './types';

export const setCients = clients => ({
  type: SET_CLIENTS_DATA,
  payload: { clients },
});

export const setDashboard = dashboard => ({
  type: SET_DASHBOARD_DATA,
  payload: { dashboard },
});

export const setOrderPendingSummary = ordersummary => ({
  type: SET_ORDER_PENDING_SUMMARY_DATA,
  payload: { ordersummary },
});

export const setOrderReportsMonthlyDashboard = ormonthlydashboard => ({
  type: SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA,
  payload: { ormonthlydashboard },
});

export const setPurchaseOrder = purchaseorder => ({
  type: SET_PURCHASE_ORDER_DATA,
  payload: { purchaseorder },
});

export const setSupportMsg = supportmsg => ({
  type: SET_RECENT_SUPPORT_MSG,
  payload: { supportmsg },
});

export const handleClientId = clientId => ({
  type: SET_CLIENT_ID_DATA,
  payload: { clientId },
});

export const handleGraphClientId = graphClientId => ({
  type: SET_GRAPH_CLIENT_ID_DATA,
  payload: { graphClientId },
});

export const setSearchClient = seachClientArray => ({
  type: SET_SEARCH_CLIENT_DATA,
  payload: { seachClientArray },
});

export const resetSearchClient = () => ({
  type: RESET_SEARCH_CLIENT_DATA,
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  const cond = type;
  if (response) {
    switch (cond) {
      case 'clients':
        dispatch(setCients(response.data.data));
        break;
      case 'dashboard':
        dispatch(setDashboard(response.data.data));
        break;
      case 'ordersummary':
        dispatch(setOrderPendingSummary(response.data.data));
        break;
      case 'ormonthlydashboard':
        dispatch(setOrderReportsMonthlyDashboard(response.data));
        break;
      case 'purchaseorder':
        dispatch(setPurchaseOrder(response.data));
        break;
      case 'supportmsg':
        dispatch(setSupportMsg(response.data));
        break;
      case 'seachclient':
        dispatch(setSearchClient(response.data.data));
        break;
      default:
        break;
    }
  }
  dispatch(hideActionLoading());
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  dispatch(hideActionLoading());
};

export const fetchClients = () => (dispatch) => {
  dispatch(showActionLoading());
  getClients().then(
    response => dispatch(successResponse(response, 'clients')),
    err => dispatch(errorResponse(err)),
  );
};

// export const fetchDashboard = (clientId, type, year, reference) => (dispatch) => {
//   dispatch(showActionLoading());
//   getDashboard(clientId, type, year, reference).then(
//     response => dispatch(successResponse(response, 'dashboard')),
//     err => dispatch(errorResponse(err)),
//   );
// };

export const fetchDashboard = (clientId, type, reference) => (dispatch) => {
  dispatch(showActionLoading());
  getDashboard(clientId, type, reference).then(
    response => dispatch(successResponse(response, 'dashboard')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderPendingSummary = () => (dispatch) => {
  dispatch(showActionLoading());
  getOrderPendingSummary().then(
    response => dispatch(successResponse(response, 'ordersummary')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderReportsMonthlyDashboard = (clientId, type, threeyears, year) => (dispatch) => {
  const ytype = typeof type === 'undefined' ? null : type;
  const yyear = typeof year === 'undefined' ? null : year;
  const ythreeyears = typeof threeyears === 'undefined' ? null : threeyears;
  dispatch(showActionLoading());
  getOrderReportsMonthly(clientId, ytype, ythreeyears, yyear).then(
    response => dispatch(successResponse(response, 'ormonthlydashboard')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchPurchaseOrder = clientId => (dispatch) => {
  dispatch(showActionLoading());
  getPurchaseOrder(clientId).then(
    response => dispatch(successResponse(response, 'purchaseorder')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchRecentSupoortMsg = () => (dispatch) => {
  dispatch(showActionLoading());
  getRecentSupoortMsg().then(
    response => dispatch(successResponse(response, 'supportmsg')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchSearchClient = (txt, limit, page) => (dispatch) => {
  dispatch(showActionLoading());
  searchClient(txt, limit, page).then(
    response => dispatch(successResponse(response, 'seachclient')),
    err => dispatch(errorResponse(err, false)),
  );
};
