/* eslint-disable no-useless-escape */
var notifdata = {
  current_page: 1,
  data: [{
    id: 573,
    parent_id: 0,
    recipient_type: 1,
    client_id: 3,
    action: 'chat_message',
    product_id: 0,
    data: {
      action: 'chat_message',
      chat_id: '17',
      subject: 'Help! My product is not editable',
      chat_key: 1606675551635414,
      client_id: 3,
      creator_id: 7,
      message_id: 1606675796679604,
      support_type: 0,
      link: 'http:\/\/54.214.178.47\/api\/chats\/17'
    },
    is_read: 1,
    created_at: '2020-11-29 18:49:56'
  }, {
    id: 572,
    parent_id: 0,
    recipient_type: 1,
    client_id: 3,
    action: 'chat_message',
    product_id: 0,
    data: {
      action: 'chat_message',
      chat_id: '17',
      subject: 'Help! My product is not editable',
      chat_key: 1606675551635414,
      client_id: 3,
      creator_id: 7,
      message_id: 1606675719898113,
      support_type: 0,
      link: 'http:\/\/54.214.178.47\/api\/chats\/17'
    },
    is_read: 1,
    created_at: '2020-11-29 18:48:40'
  }, {
    id: 547,
    parent_id: 0,
    recipient_type: 1,
    client_id: 3,
    action: 're_stock',
    product_id: 225,
    data: {
      sku: 303,
      action: 're_stock',
      quantity: 69,
      client_id: 3,
      creator_id: 0,
      product_id: 225,
      link: 'http:\/\/54.214.178.47\/api\/products\/225',
      description: 'Awesome Toes - Large Size'
    },
    is_read: 1,
    created_at: '2020-10-07 02:30:45'
  }, {
    id: 485,
    parent_id: 0,
    recipient_type: 1,
    client_id: 3,
    action: 'out_of_stock',
    product_id: 399,
    data: {
      sku: '4095-1',
      action: 'out_of_stock',
      quantity: -1,
      client_id: 3,
      creator_id: 0,
      product_id: 399,
      link: 'http:\/\/54.214.178.47\/api\/products\/399',
      description: 'Trapeze stand rod'
    },
    is_read: 1,
    created_at: '2020-07-31 02:30:50'
  }, {
    id: 484,
    parent_id: 0,
    recipient_type: 1,
    client_id: 3,
    action: 'low_in_stock',
    product_id: 182,
    data: {
      sku: 430,
      action: 'low_in_stock',
      quantity: 49,
      client_id: 3,
      creator_id: 0,
      product_id: 182,
      link: 'http:\/\/54.214.178.47\/api\/products\/182',
      description: 'Daisy Chains'
    },
    is_read: 1,
    created_at: '2020-07-30 18:11:02'
  }, {
    id: 477,
    parent_id: 0,
    recipient_type: 1,
    client_id: 3,
    action: 'out_of_stock',
    product_id: 390,
    data: {
      sku: '4096-1',
      action: 'out_of_stock',
      quantity: -2,
      client_id: 3,
      creator_id: 0,
      product_id: 390,
      link: 'http:\/\/54.214.178.47\/api\/products\/390',
      description: 'Trapeze Stand Connector Rod'
    },
    is_read: 1,
    created_at: '2020-07-21 02:30:49'
  }],
  first_page_url: 'http:\/\/54.214.178.47\/api\/notifications?page=1',
  from: 1,
  last_page: 14,
  last_page_url: 'http:\/\/54.214.178.47\/api\/notifications?page=14',
  next_page_url: 'http:\/\/54.214.178.47\/api\/notifications?page=2',
  path: 'http:\/\/54.214.178.47\/api\/notifications',
  per_page: '6',
  prev_page_url: null,
  to: 6,
  total: 84
};
var notifunread = {
  data: {
    type: 'total unread notifications',
    attributes: {
      notification_count: 0,
      total_unread: 48
    }
  }
}; // eslint-disable-next-line

export var getNotificationData = function getNotificationData() {
  return notifdata;
};
export var getNotificationUnreadData = function getNotificationUnreadData() {
  return notifunread;
};