/* eslint-disable max-len */

/* import { makeRequest, METHODS } from '@entando/apimanager';
import { getCountriesData } from 'mocks/countries'; */
import axios from 'axios';
import { wmsheader } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

/* export const getCountries = () => (
  makeRequest({
    uri: '/api/address/countries',
    method: METHODS.GET,
    mockResponse: getCountriesData(),
    useAuthentication: false,
  })
); */

export const getCountries = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/address/countries`,
  headers: wmsheader(),
});

export const getUSState = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/address/states`,
  headers: wmsheader(),
});

export const getUSZipCodeList = (limit, zip, state = '', city = '') => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/autocomplete/address/zips?limit=${limit}&zip=${zip}&state=${state}&city=${city}`,
  headers: wmsheader(),
});
