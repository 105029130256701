import { createSelector } from 'reselect';

export const dashboardSelector = state => state.reports.dashboard;
export const clientsSelector = state => state.reports.clients;
export const orderSummarySelector = state => state.reports.ordersummary;
export const supportMsgSelector = state => state.reports.supportmsg;
export const purchaseOrderSelector = state => state.reports.purchaseorder;
export const orMonthlyDashboardSelector = state => state.reports.ormonthlydashboard;
export const clientIdSelector = state => state.reports.clientId;
export const graphClientIdSelector = state => state.reports.graphClientId;
export const searchClientSelector = state => state.reports.seachClientArray;

export const clientsData = createSelector(
  clientsSelector,
  clients => clients,
);

export const orderSummaryData = createSelector(
  orderSummarySelector,
  ordersummary => ordersummary,
);

export const purchaseOrderData = createSelector(
  purchaseOrderSelector,
  purchaseorder => purchaseorder,
);

export const supportMsgData = createSelector(
  supportMsgSelector,
  supportmsg => supportmsg,
);

export const dashboardData = createSelector(
  dashboardSelector,
  dashboard => dashboard,
);

export const getOrderMonthlyReportsDashboard = createSelector(
  orMonthlyDashboardSelector,
  ormonthlydashboard => ormonthlydashboard,
);

export const getClientId = createSelector(
  clientIdSelector,
  clientId => clientId,
);

export const getGraphClientId = createSelector(
  graphClientIdSelector,
  graphClientId => graphClientId,
);

export const getSearchClient = createSelector(
  searchClientSelector,
  seachClientArray => seachClientArray,
);
