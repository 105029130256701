/* eslint-disable no-console */
import { notifList, notifRead, notifUnread, notificationSettings, updateNotifSettings, resetNotification } from 'api/notification';
import { showActionLoading, hideActionLoading } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_NOTIF_DATA,
  SET_NOTIFLIST_DATA,
  SET_MORE_NOTIFLIST_DATA,
  UNREAD_NOTIF_DATA,
  SET_NOTIFSETTINGS_DATA,
} from './types';

export const setUnreadNotif = support => ({
  type: UNREAD_NOTIF_DATA,
  payload: { support },
});

export const setNotif = support => ({
  type: SET_NOTIF_DATA,
  payload: { support },
});

export const setNotifications = support => ({
  type: SET_NOTIFLIST_DATA,
  payload: { support },
});

export const setMoreNotifications = support => ({
  type: SET_MORE_NOTIFLIST_DATA,
  payload: { support },
});

export const setNotificationSettings = settings => ({
  type: SET_NOTIFSETTINGS_DATA,
  payload: { settings },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  if (response) {
    if (type === 'list') {
      dispatch(setNotif(response.data));
    }
    if (type === 'notiflist') {
      dispatch(setNotifications(response.data));
    }
    if (type === 'morenotiflist') {
      dispatch(setMoreNotifications(response.data));
    }
    if (type === 'unreadnotif') {
      dispatch(setUnreadNotif(response.data));
    }
    if (type === 'nofifsettings') {
      dispatch(setNotificationSettings(response.data));
    }
  }

  dispatch(hideActionLoading());
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  dispatch(hideActionLoading());
};

/* mock data quuery */

export const fetchNotifList = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  notifList().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(successResponse({ data: json.payload }, 'notiflist'));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', '));
        resolve();
      }
    });
  });
});

export const unreadNotif = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  notifUnread().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(successResponse({ data: json.payload }, 'unreadnotif'));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', '));
        resolve();
      }
    });
  });
});

/* end mock data query */

/* export const fetchNotifList = (limit, page = 1, allnotif = false) => async (dispatch) => {
  try {
    const list = allnotif ? 'notiflist' : 'list';
    dispatch(showActionLoading());
    const response = await notifList(limit, page);
    dispatch(successResponse(response, list));
  } catch (err) {
    dispatch(errorResponse(err));
  }
}; */

export const fetchMoreNotifList = (limit, page = 1) => async (dispatch) => {
  try {
    dispatch(showActionLoading());
    const response = await notifList(limit, page);
    dispatch(successResponse(response, 'morenotiflist'));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const readNotif = id => (dispatch) => {
  dispatch(showActionLoading());
  notifRead(id).then(
    response => dispatch(successResponse(response, false)),
    err => dispatch(errorResponse(err)),
  );
};

/* export const unreadNotif = () => (dispatch) => {
  dispatch(showActionLoading());
  notifUnread().then(
    response => dispatch(successResponse(response, 'unreadnotif')),
    err => dispatch(errorResponse(err)),
  );
}; */

export const fetchNotificationSettings = () => async (dispatch) => {
  try {
    dispatch(showActionLoading());
    const response = await notificationSettings();
    dispatch(successResponse(response, 'nofifsettings'));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const updateNotif = data => (dispatch) => {
  dispatch(showActionLoading());
  updateNotifSettings(data).then(
    response => dispatch(successResponse(response, 'nofifsettings')),
    err => dispatch(errorResponse(err)),
  );
};

export const clearNotification = () => (dispatch) => {
  dispatch(showActionLoading());
  resetNotification().then(
    response => dispatch(successResponse(response, 'unreadnotif')),
    err => dispatch(errorResponse(err)),
  );
};

