import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { logoutUser, setHeader } from 'state/logged-user/actions';
import { isItLogged, getLoginData, getHeaderStatus } from 'state/logged-user/selectors';
import { fetchCountries } from 'state/address/actions';
import { getCountries } from 'state/address/selectors';
import * as uiActionCreators from 'state/modal-drawer/actions';
import { fetchClients, handleClientId, handleGraphClientId, fetchSearchClient, resetSearchClient } from 'state/reports/actions';
import { clientsData, getClientId, getGraphClientId, getSearchClient } from 'state/reports/selectors';
import { reloadComponent, showAlertModal, showModal } from 'state/modal-drawer/selectors';
import { fetchNotifList, fetchMoreNotifList, readNotif, unreadNotif, clearNotification } from 'state/notification/actions';
import { getNotifData, getUnreadNotifData, getNotifListData } from 'state/notification/selectors';
import { isItActionShowingLoad, getLoadType } from 'state/loading/selectors';
import { setLoadType } from 'state/loading/actions';
import { apiRefresh } from 'state/settings/actions';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import withTheme from 'ui/withTheme';

const Loading = () => <div>&nbsp;</div>;

const Header = Loadable({
  loader: () => import(/* webpackChunkName: "header" */'ui/components/common/wms/Header'),
  loading: Loading,
});

const Navigation = Loadable({
  loader: () => import(/* webpackChunkName: "navigation" */'ui/components/common/wms/Navigation'),
  loading: Loading,
});

const AlertModal = Loadable({
  loader: () => import(/* webpackChunkName: "alert-modal" */'ui/components/modal/AlertModal'),
  loading: Loading,
});

let WAIT_INTERVAL = (1000 * 60) * 20; // 20 minutes interval

class WMSLayout extends Component {
  componentDidMount() {
    const { location, logged } = this.props;
    if (location.search !== '') {
      localStorage.setItem('searchpath', location.search);
    }
    if (!logged) {
      if (location.pathname !== '/reset' && location.pathname !== '/reset-link') {
        this.props.history.push('/login'); // eslint-disable-line
      }
    }
    if (logged) {
      if (location.pathname === '/login' || location.pathname === '/reset' || location.pathname === '/reset-link') {
        this.props.history.push('/'); // eslint-disable-line
      }
      const { countries, countryList } = this.props;
      if (countries === null) {
        countryList();
      }
      const { clients, getclients } = this.props;
      if (!Object.keys(clients).length) {
        getclients();
      }
      if (localStorage.getItem('searchpath') === '?setinterval') {
        WAIT_INTERVAL = (1000 * 60) * 1;
      }
      if (localStorage.getItem('searchpath') === '?clearinterval') {
        WAIT_INTERVAL = (1000 * 60) * 20;
      }
      this.rtimer = setInterval(() => { this.refreshToken(); }, WAIT_INTERVAL);
    }
  }

  refreshToken = () => {
    const { refreshApi } = this.props;
    refreshApi();
  }

  render() {
    const { location, logged, ...otherDatas } = this.props;
    const { actions, alertModal, ...others } = this.props;
    return (
      <div className="App">
        { logged && location.pathname !== '/login' && location.pathname !== '/reset' && location.pathname !== '/reset-link' ?
          <Fragment>
            <Header {...otherDatas} />
            <Navigation {...others} />
          </Fragment>
        : null }
        {
          this.props.children
        }
        {/* {React.cloneElement(this.props.children, { loggedIn: false })} */}
        <AlertModal
          modalKey="alert"
          modalState={alertModal}
          closeAction={actions.ui.closeAlertModal}
          {...others}
        />
      </div>
    );
  }
}

WMSLayout.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  logged: PropTypes.bool.isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  countries: PropTypes.array, // eslint-disable-line
  countryList: PropTypes.func.isRequired,
  getclients: PropTypes.func.isRequired,
  setClientId: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Object).isRequired,
};

export const mapStateToProps = state => ({
  logged: isItLogged(state),
  profile: getLoginData(state),
  reload: reloadComponent(state),
  modal: showModal(state),
  alertModal: showAlertModal(state),
  countries: getCountries(state),
  headerStatus: getHeaderStatus(state),
  notifdata: getNotifData(state),
  unreadnotifdata: getUnreadNotifData(state),
  notiflistdata: getNotifListData(state),
  clients: clientsData(state),
  clientId: getClientId(state),
  graphClientId: getGraphClientId(state),
  actionloading: isItActionShowingLoad(state),
  getloadtype: getLoadType(state),
  clientlist: getSearchClient(state),
});

export const mapDispatchToProps = dispatch => ({
  handleLogout: () => {
    dispatch(logoutUser());
    setTimeout(() => {
      window.location.href = '/login';
    }, 1);
  },
  actions: { ui: bindActionCreators(uiActionCreators, dispatch) },
  setHeader: () => dispatch(setHeader('')),
  countryList: () => dispatch(fetchCountries()),
  notifList: (limit, page, all) => dispatch(fetchNotifList(limit, page, all)),
  moreNotifList: (limit, page, all) => dispatch(fetchMoreNotifList(limit, page, all)),
  notifRead: id => dispatch(readNotif(id)),
  notifUnRead: () => dispatch(unreadNotif()),
  clearNotif: () => dispatch(clearNotification()),
  getclients: () => dispatch(fetchClients()),
  setClientId: id => dispatch(handleClientId(id)),
  setGraphClientId: id => dispatch(handleGraphClientId(id)),
  setloadtype: type => dispatch(setLoadType(type)),
  searchClient: (txt, limit, page) => dispatch(fetchSearchClient(txt, limit, page)),
  resetSearchClient: () => dispatch(resetSearchClient()),
  refreshApi: () => dispatch(apiRefresh()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(WMSLayout)));
