import {
  SET_PRODUCTS_DATA,
  SET_MORE_PRODUCTS_DATA,
  RESET_PRODUCTS_DATA,
  SET_PRODUCT_DETAILS_DATA,
  RESET_PRODUCT_DETAILS_DATA,
  SET_PRODUCT_TYPES_DATA,
  SET_PRODUCT_INFO_DATA,
  SET_VENDOR_DATA,
  SET_VENDOR_ORDERS_DATA,
  SET_VENDOR_AUTOCOMPLETE_DATA,
  RESET_VENDOR_AUTOCOMPLETE_DATA,
  SET_MANUFACTURER_DATA,
  SET_MANUFACTURER_AUTOCOMPLETE_DATA,
  RESET_MANUFACTURER_AUTOCOMPLETE_DATA,
  SET_PRODUCT_AUTOCOMPLETE_DATA,
  RESET_PRODUCT_AUTOCOMPLETE_DATA,
  SET_FILTERS_DATA,
  SET_IMPORT_INFO_DATA,
  SET_IMPORT_PRODUCTS_DATA,
  SET_SALES_ORDER_DATA,
  SET_MORE_SALES_ORDER_DATA,
  SET_SALES_ORDER_PAGE_DATA,
  SET_STOCK_ALERT_DATA,
  SET_MORE_STOCK_ALERT_DATA,
  ADD_STOCK_ALERT_DATA,
  SET_STOCK_RECIPIENT_DATA,
  SET_RECEIPTS_DATA,
  SET_MORE_RECEIPTS_DATA,
  SET_SUPPLY_RECEIPT_CLIENT_ID,
  SET_ACTIVE_CLIENTS_DATA,
  SET_CARRIER_AUTOCOMPLETE_DATA,
  RESET_CARRIER_AUTOCOMPLETE_DATA,
  SET_PO_AUTOCOMPLETE_DATA,
  RESET_PO_AUTOCOMPLETE_DATA,
  SET_SUPPLY_RECEIPT_DETAILS_DATA,
  RESET_SUPPLY_RECEIPT_DETAILS_DATA,
} from './types';

const initialState = {
  list: {
    dataObj: {},
    dataArr: [],
  },
  supplyreceipts: {
    dataObj: {},
    dataArr: [],
  },
  detail: {},
  supplyreceiptdetail: {},
  prodTypes: [],
  prodInfo: {},
  vendorData: {},
  vendorOrdersData: {},
  autocompleteVendor: {},
  autocompleteCarrier: {},
  autocompletePO: {},
  manufacturerData: {},
  autocompleteManufacturer: {},
  autocompleteProduct: {},
  filters: [],
  importinfo: {},
  importproducts: {},
  salesorder: {
    dataObj: {},
    dataArr: [],
  },
  salesorderpage: 1,
  stockalerts: {
    dataObj: {},
    dataArr: [],
  },
  addstock: {},
  stockalertrecipient: {},
  SRClientId: null,
  activeClients: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PRODUCTS_DATA: {
      return Object.assign({}, state, {
        list: {
          dataObj: action.payload.products,
          dataArr: action.payload.products.data,
        },
      });
    }
    case SET_MORE_PRODUCTS_DATA: {
      const dataArr = state.list.dataArr.concat(action.payload.products.data);
      return Object.assign({}, state, {
        list: {
          dataObj: action.payload.products,
          dataArr,
        },
      });
    }
    case RESET_PRODUCTS_DATA: {
      return Object.assign({}, state, {
        list: {
          dataObj: {},
          dataArr: [],
        },
      });
    }
    case SET_RECEIPTS_DATA: {
      return Object.assign({}, state, {
        supplyreceipts: {
          dataObj: action.payload.receipts,
          dataArr: action.payload.receipts.data,
        },
      });
    }
    case SET_MORE_RECEIPTS_DATA: {
      const dataArr = state.supplyreceipts.dataArr.concat(action.payload.receipts.data);
      return Object.assign({}, state, {
        supplyreceipts: {
          dataObj: action.payload.receipts,
          dataArr,
        },
      });
    }
    case SET_PRODUCT_DETAILS_DATA: {
      return { ...state, detail: action.payload.detail };
    }
    case RESET_PRODUCT_DETAILS_DATA: {
      return { ...state, detail: {} };
    }
    case SET_PRODUCT_TYPES_DATA: {
      return { ...state, prodTypes: action.payload.prodTypes };
    }
    case SET_PRODUCT_INFO_DATA: {
      return { ...state, prodInfo: action.payload.prodInfo };
    }
    case SET_VENDOR_DATA: {
      return { ...state, vendorData: action.payload.vendorData };
    }
    case SET_VENDOR_ORDERS_DATA: {
      return { ...state, vendorOrdersData: action.payload.vendorOrdersData };
    }
    case SET_VENDOR_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteVendor: action.payload.autocompleteVendor };
    }
    case RESET_VENDOR_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteVendor: {} };
    }
    case SET_CARRIER_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteCarrier: action.payload.autocompleteCarrier };
    }
    case RESET_CARRIER_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteCarrier: {} };
    }
    case SET_PO_AUTOCOMPLETE_DATA: {
      return { ...state, autocompletePO: action.payload.autocompletePO };
    }
    case RESET_PO_AUTOCOMPLETE_DATA: {
      return { ...state, autocompletePO: {} };
    }
    case SET_MANUFACTURER_DATA: {
      return { ...state, manufacturerData: action.payload.manufacturerData };
    }
    case SET_MANUFACTURER_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteManufacturer: action.payload.autocompleteManufacturer };
    }
    case RESET_MANUFACTURER_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteManufacturer: {} };
    }
    case SET_PRODUCT_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteProduct: action.payload.autocompleteProduct };
    }
    case RESET_PRODUCT_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteProduct: {} };
    }
    case SET_FILTERS_DATA: {
      return { ...state, filters: action.payload.filters };
    }
    case SET_IMPORT_INFO_DATA: {
      return { ...state, importinfo: action.payload.importinfo };
    }
    case SET_IMPORT_PRODUCTS_DATA: {
      return { ...state, importproducts: action.payload.importproducts };
    }
    case SET_SALES_ORDER_DATA: {
      return Object.assign({}, state, {
        salesorder: {
          dataObj: action.payload.salesorder,
          dataArr: action.payload.salesorder.data,
        },
      });
    }
    case SET_MORE_SALES_ORDER_DATA: {
      const dataArr = state.salesorder.dataArr.concat(action.payload.salesorder.data);
      return Object.assign({}, state, {
        salesorder: {
          dataObj: action.payload.salesorder,
          dataArr,
        },
      });
    }
    case SET_SALES_ORDER_PAGE_DATA: {
      return { ...state, salesorderpage: action.payload.salesorderpage };
    }
    case SET_STOCK_ALERT_DATA: {
      return Object.assign({}, state, {
        stockalerts: {
          dataObj: action.payload.stockalerts,
          dataArr: action.payload.stockalerts.data,
        },
      });
    }
    case SET_MORE_STOCK_ALERT_DATA: {
      const dataArr = state.stockalerts.dataArr.concat(action.payload.stockalerts.data);
      return Object.assign({}, state, {
        stockalerts: {
          dataObj: action.payload.stockalerts,
          dataArr,
        },
      });
    }
    case ADD_STOCK_ALERT_DATA: {
      return { ...state, addstock: action.payload.addstock };
    }
    case SET_STOCK_RECIPIENT_DATA: {
      return { ...state, stockalertrecipient: action.payload.stockrecipient.data };
    }
    case SET_SUPPLY_RECEIPT_CLIENT_ID: {
      return { ...state, SRClientId: action.payload.SRClientId };
    }
    case SET_ACTIVE_CLIENTS_DATA: {
      return { ...state, activeClients: action.payload.activeClients };
    }
    case SET_SUPPLY_RECEIPT_DETAILS_DATA: {
      return { ...state, supplyreceiptdetail: action.payload.supplyreceiptdetail };
    }
    case RESET_SUPPLY_RECEIPT_DETAILS_DATA: {
      return { ...state, supplyreceiptdetail: {} };
    }
    default: return state;
  }
};

export default reducer;
