import {
  SET_CALENDAR_EVENTS_DATA,
  SET_SINGLE_EVENT_DATA,
  SET_ALL_CLIENTS_DATA,
  SET_MORE_CLIENTS_DATA,
  SET_CLIENT_DETAILS_DATA,
  SET_ADMIN_USERS_DATA,
  SET_MORE_ADMIN_USERS_DATA,
  SET_SAVE_ADMIN_USERS_DATA,
  SET_UPDATE_ADMIN_USERS_DATA,
  SET_DELETE_ADMIN_USERS_DATA,
  SET_ACTIVATE_CLIENT_DATA,
  SET_DEACTIVATE_CLIENT_DATA,
} from './types';

const initialState = {
  calevents: [],
  singleevent: {},
  clientsObj: {
    dataObj: {},
    dataArr: [],
  },
  clientdetails: {},
  usersObj: {
    dataObj: {},
    dataArr: [],
  },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CALENDAR_EVENTS_DATA: {
      return { ...state, calevents: action.payload.calevents };
    }
    case SET_SINGLE_EVENT_DATA: {
      return { ...state, singleevent: action.payload.singleevent };
    }
    case SET_ALL_CLIENTS_DATA: {
      return Object.assign({}, state, {
        clientsObj: {
          dataObj: action.payload.clients,
          dataArr: action.payload.clients.data,
        },
      });
    }
    case SET_MORE_CLIENTS_DATA: {
      const dataArr = state.clientsObj.dataArr.concat(action.payload.clients.data);
      return Object.assign({}, state, {
        clientsObj: {
          dataObj: action.payload.clients,
          dataArr,
        },
      });
    }
    case SET_CLIENT_DETAILS_DATA: {
      return { ...state, clientdetails: action.payload.clientdetails };
    }
    case SET_ADMIN_USERS_DATA: {
      return Object.assign({}, state, {
        usersObj: {
          dataObj: action.payload.users,
          dataArr: action.payload.users.data,
        },
      });
    }
    case SET_MORE_ADMIN_USERS_DATA: {
      const dataArr = state.usersObj.dataArr.concat(action.payload.users.data);
      return Object.assign({}, state, {
        usersObj: {
          dataObj: action.payload.users,
          dataArr,
        },
      });
    }
    case SET_SAVE_ADMIN_USERS_DATA: {
      const dataArr = [...[action.payload.users], ...state.usersObj.dataArr];
      return Object.assign({}, state, {
        usersObj: {
          ...state.usersObj,
          dataArr,
        },
      });
    }
    case SET_UPDATE_ADMIN_USERS_DATA: {
      const { dataArr } = state.usersObj;
      dataArr.map((data, index) => { // eslint-disable-line
        if (data.attributes.id === action.payload.id) {
          dataArr[index].attributes.firstname = action.payload.users.attributes.firstname;
          dataArr[index].attributes.lastname = action.payload.users.attributes.lastname;
          dataArr[index].attributes.email = action.payload.users.attributes.email;
          dataArr[index].attributes.updated_at = new Date();
        }
      });
      return Object.assign({}, state, {
        usersObj: {
          ...state.usersObj,
          dataArr,
        },
      });
    }
    case SET_DELETE_ADMIN_USERS_DATA: {
      const dataArr = state.usersObj.dataArr.filter(sel => parseInt(sel.attributes.id, 10) !== parseInt(action.payload.id, 10)); // eslint-disable-line
      return Object.assign({}, state, {
        usersObj: {
          ...state.usersObj,
          dataArr,
        },
      });
    }
    case SET_ACTIVATE_CLIENT_DATA: {
      const { dataArr } = state.clientsObj;
      dataArr.map((data, index) => { // eslint-disable-line
        if (data.attributes.id === action.payload.client.attributes.id) {
          dataArr[index].attributes.status = 'A';
        }
      });
      return Object.assign({}, state, {
        clientsObj: {
          ...state.clientsObj,
          dataArr,
        },
      });
    }
    case SET_DEACTIVATE_CLIENT_DATA: {
      const { dataArr } = state.clientsObj;
      dataArr.map((data, index) => { // eslint-disable-line
        if (data.attributes.id === action.payload.id) {
          dataArr[index].attributes.status = 'I';
        }
      });
      return Object.assign({}, state, {
        clientsObj: {
          ...state.clientsObj,
          dataArr,
        },
      });
    }
    default: return state;
  }
};

export default reducer;
