/* eslint-disable max-len */
import { createSelector } from 'reselect';

export const getLoggedUser = state => state.loggedUser;
export const loginDataSelector = state => state.loggedUser.data;
export const loginSettingsSelector = state => state.loggedUser.cusettings;
export const settingsSelector = state => state.loggedUser.settings;

export const getUsername = createSelector(
  getLoggedUser,
  user => user.username,
);

export const getToken = createSelector(
  getLoggedUser,
  user => user.token,
);

export const getLoginData = createSelector(
  getLoggedUser,
  user => user.user,
);

export const getUserLoginSettings = createSelector(
  loginSettingsSelector,
  cusettings => cusettings,
);

export const getHeaderStatus = createSelector(
  getLoggedUser,
  user => user.headerStatus,
);

export const getSettings = createSelector(
  settingsSelector,
  settings => settings,
);

export const isItLogged = createSelector(
  [getLoggedUser],
  user => (!!user.user),
);
