import { config, setApi } from '@entando/apimanager';
import { ROUTE_WMS_DASHBOARD } from './routedir';


const configApiManager = {
  domain: null, // eslint-disable-line
  useMocks: false,
};

export default (store) => {
  config(store, ROUTE_WMS_DASHBOARD, ROUTE_WMS_DASHBOARD);
  store.dispatch(setApi(configApiManager));
};
