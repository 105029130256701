import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import WMSLayout from 'ui/hoc/WMSLayout';
import RoutesContainer from 'ui/route/RoutesContainer';
import withTheme from 'ui/withTheme';

class App extends Component {
  render() {
    return (
      <Router>
        <WMSLayout {...this.props}>
          <RoutesContainer />
        </WMSLayout>
      </Router>
    );
  }
}

export default withTheme((App));
