/* import { updateUser } from 'api/staff'; */
import Cookies from 'universal-cookie';
import { showLoading, hideLoading } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { SET_USER, UNSET_USER, SET_HEADER_STATUS, SET_SETTINGS_DATA } from './types';

const cookies = new Cookies('wmsCookie');

export const wmsheader = () => {
  const cockiedata = cookies.get('admintoken');
  const hdata = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.api+json',
    Authorization: `Bearer ${cockiedata}`,
  };
  return hdata;
};

export const setUser = user => ({
  type: SET_USER,
  payload: {
    user,
  },
});

export const unsetUser = () => ({
  type: UNSET_USER,
  payload: {
    user: {
      username: null,
      token: null,
      data: null,
    },
  },
});

export const setAdminHeaderStatus = headerStatus => ({
  type: SET_HEADER_STATUS,
  payload: {
    headerStatus,
  },
});

export const setSettings = settings => ({
  type: SET_SETTINGS_DATA,
  payload: { settings },
});

export const loginUser = logindata => (dispatch) => {
  dispatch(setUser({
    user: logindata.attributes.user,
    adminHeaderStatus: '',
  }));
  cookies.set('adminHeaderStatus', '', { path: '/' });
  cookies.set('userdata', JSON.stringify(logindata.attributes.user), { path: '/' });
  cookies.set('admintoken', logindata.attributes.access_token, { path: '/' });
};

export const logoutUser = () => (dispatch) => {
  dispatch(unsetUser());

  cookies.remove('adminHeaderStatus');
  cookies.remove('userdata');
  cookies.remove('admintoken');
  localStorage.clear();
};

export const token = () => cookies.get('admintoken');

export const getUsername = () => cookies.get('username');

export const setToken = tokendata => (dispatch) => {
  cookies.set('admintoken', tokendata, { path: '/' });
  dispatch(setAdminHeaderStatus(''));
};

export const setHeader = status => (dispatch) => {
  cookies.set('adminHeaderStatus', status, { path: '/' });
  dispatch(setAdminHeaderStatus(status));
};

export const setUserData = data => (dispatch) => {
  dispatch(showLoading());
  dispatch(setUser({
    data,
  }));
  cookies.set('data', JSON.stringify(data), { path: '/' });
  dispatch(hideLoading());
};

export const successResponse = response => (dispatch) => {
  dispatch(responseCheker(response));
  if (response.data) {
    // success here
  }
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    // error here
  }
};

/* export const setUserSettings = (uid, data) => (dispatch) => {
  localStorage.setItem('settings', JSON.stringify(data));
  dispatch(setSettings(data));
  updateUser(uid, { settings: JSON.stringify(data) }).then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err)),
  );
}; */

