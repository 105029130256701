export const SET_DASHBOARD_DATA = 'reports/set-dashboard-data';
export const SET_CLIENTS_DATA = 'reports/set-clients-data';
export const SET_ORDER_PENDING_SUMMARY_DATA = 'reports/set-order-pending-summary-data';
export const SET_RECENT_SUPPORT_MSG = 'reports/set-recent-support-msg-data';
export const SET_PURCHASE_ORDER_DATA = 'reports/set-purchase-order-data';
export const SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA = 'reports/set-order-reports-monthly-dashboard-data';
export const SET_CLIENT_ID_DATA = 'reports/set-client-id-data';
export const SET_GRAPH_CLIENT_ID_DATA = 'reports/set-graph-client-id-data';
export const SET_SEARCH_CLIENT_DATA = 'reports/set-search-client-data';
export const RESET_SEARCH_CLIENT_DATA = 'reports/reset-search-client-data';
