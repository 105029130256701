/* eslint-disable max-len */

/* import { makeRequest, METHODS } from '@entando/apimanager';
import { getDashboardData, getMonthlyReportsData } from 'mocks/dashboard'; */
import axios from 'axios';
import { wmsheader } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

/* export const getDashboard = () => (
  makeRequest({
    uri: '/api/dashboard',
    method: METHODS.GET,
    mockResponse: getDashboardData(),
    useAuthentication: false,
  })
); */

/* export const getOrderReportsMonthly = () => (
  makeRequest({
    uri: '/reports/orders/monthly',
    method: METHODS.GET,
    mockResponse: getMonthlyReportsData(),
    useAuthentication: false,
  })
); */

export const getClients = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/clients`,
  headers: wmsheader(),
});

// export const getDashboard = (clientId, type, year, reference) => axios({
//   method: 'GET',
//   url: `${SITE_SERVER}/admin/dashboard?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}type=${type}${year !== null && typeof year !== 'undefined' ? `&year=${year}` : ''}${reference !== null && typeof reference !== 'undefined' ? `&reference_date_type=${reference}` : ''}`,
//   headers: wmsheader(),
// });

export const getDashboard = (clientId, type, reference) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/dashboard?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}type=${type}${reference !== null && typeof reference !== 'undefined' ? `&reference_date_type=${reference}` : ''}`,
  headers: wmsheader(),
});

export const getOrderPendingSummary = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/dashboard/order_pending_summary?status=A`,
  headers: wmsheader(),
});

export const getOrderReportsMonthly = (clientId, type, threeyears, year) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/reports/orders/monthly?${clientId !== null ? `client_id=${clientId}` : ''}${type !== null ? `&type=${type}` : ''}${threeyears !== null ? `&three_years=${threeyears}` : ''}${year !== null ? `&year=${year}` : ''}`,
  headers: wmsheader(),
});

export const getPurchaseOrder = clientId => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/vendorders?page=1&limit=3${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}` : ''}`,
  headers: wmsheader(),
});

export const getRecentSupoortMsg = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/chats?type_id=0&limit=4&page=1&status=O`,
  headers: wmsheader(),
});

export const searchClient = (txt, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/autocomplete/clients?limit=${limit}&page=${page}&text=${txt}`,
  headers: wmsheader(),
});

