/* eslint-disable max-len */
/* eslint-disable no-console */
import {
  getCalendarEvents,
  createCalendarEvent,
  saveCalendarEvent,
  deleteCalendarEvent,
  getClients,
  getAdminUsers,
  addAdminUser,
  updateAdminUser,
  updateClientPassword,
  deleteAdminUser,
  activateClient,
  deactivateClient,
  getClientDetails,
  refreshApi,
} from 'api/settings';
import { showActionLoading, hideActionLoading } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_CALENDAR_EVENTS_DATA,
  SET_SINGLE_EVENT_DATA,
  SET_ALL_CLIENTS_DATA,
  SET_MORE_CLIENTS_DATA,
  SET_CLIENT_DETAILS_DATA,
  SET_ADMIN_USERS_DATA,
  SET_MORE_ADMIN_USERS_DATA,
  SET_SAVE_ADMIN_USERS_DATA,
  SET_UPDATE_ADMIN_USERS_DATA,
  SET_DELETE_ADMIN_USERS_DATA,
  SET_ACTIVATE_CLIENT_DATA,
  SET_DEACTIVATE_CLIENT_DATA,
} from './types';

export const setEvents = calevents => ({
  type: SET_CALENDAR_EVENTS_DATA,
  payload: { calevents },
});

export const setSingleEvent = singleevent => ({
  type: SET_SINGLE_EVENT_DATA,
  payload: { singleevent },
});

export const setClients = clients => ({
  type: SET_ALL_CLIENTS_DATA,
  payload: { clients },
});

export const setMoreClients = clients => ({
  type: SET_MORE_CLIENTS_DATA,
  payload: { clients },
});

export const setAdminUsers = users => ({
  type: SET_ADMIN_USERS_DATA,
  payload: { users },
});

export const setMoreAdminUsers = users => ({
  type: SET_MORE_ADMIN_USERS_DATA,
  payload: { users },
});

export const setSaveAdminUser = users => ({
  type: SET_SAVE_ADMIN_USERS_DATA,
  payload: { users },
});

export const setUpdateAdminUser = (users, id) => ({
  type: SET_UPDATE_ADMIN_USERS_DATA,
  payload: { users, id },
});

export const setDeleteAdminUser = id => ({
  type: SET_DELETE_ADMIN_USERS_DATA,
  payload: { id },
});

export const setActivateClient = client => ({
  type: SET_ACTIVATE_CLIENT_DATA,
  payload: { client },
});

export const setDeactivateClient = id => ({
  type: SET_DEACTIVATE_CLIENT_DATA,
  payload: { id },
});

export const setClientDetails = clientdetails => ({
  type: SET_CLIENT_DETAILS_DATA,
  payload: { clientdetails },
});

export const successResponse = (response, type, id) => (dispatch) => {
  dispatch(responseCheker(response));
  const cond = type;
  if (response) {
    switch (cond) {
      case 'getevents':
        dispatch(setEvents(response.data.data));
        break;
      case 'calendarevent':
        dispatch(setSingleEvent(response.data.data));
        break;
      case 'getclients':
        dispatch(setClients(response.data));
        break;
      case 'moreclients':
        dispatch(setMoreClients(response.data));
        break;
      case 'getadminusers':
        dispatch(setAdminUsers(response.data));
        break;
      case 'moreadminusers':
        dispatch(setMoreAdminUsers(response.data));
        break;
      case 'saveuser':
        dispatch(setSaveAdminUser(response.data.data));
        break;
      case 'updateuser':
        dispatch(setUpdateAdminUser(response.data.data, id));
        break;
      case 'deleteuser':
        dispatch(setDeleteAdminUser(id));
        break;
      case 'activateclient':
        dispatch(setActivateClient(response.data.data));
        break;
      case 'deactivateclient':
        dispatch(setDeactivateClient(id));
        break;
      case 'clientdetails':
        dispatch(setClientDetails(response.data));
        break;
      default:
        break;
    }
  }
  dispatch(hideActionLoading());
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  dispatch(hideActionLoading());
};

export const addCalendarEvent = data => (dispatch) => {
  dispatch(showActionLoading());
  createCalendarEvent(JSON.stringify(data)).then(
    response => dispatch(successResponse(response, 'calendarevent')),
    err => dispatch(errorResponse(err)),
  );
};

export const updateCalendarEvent = (id, data) => (dispatch) => {
  dispatch(showActionLoading());
  saveCalendarEvent(id, JSON.stringify(data)).then(
    response => dispatch(successResponse(response, 'calendarevent')),
    err => dispatch(errorResponse(err)),
  );
};

export const delCalendarEvent = id => (dispatch) => {
  dispatch(showActionLoading());
  deleteCalendarEvent(id).then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchCalendarEvents = (type, day, month, year) => (dispatch) => {
  dispatch(showActionLoading());
  getCalendarEvents(type, day, month, year).then(
    response => dispatch(successResponse(response, 'getevents')),
    err => dispatch(errorResponse(err)),
  );
};

export const getAllClients = (status, limit, page, more = false) => (dispatch) => {
  dispatch(showActionLoading());
  const moredata = more ? 'moreclients' : 'getclients';
  getClients(status, limit, page).then(
    response => dispatch(successResponse(response, moredata)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchAdminUsers = (limit, page, more = false) => (dispatch) => {
  dispatch(showActionLoading());
  const moredata = more ? 'moreadminusers' : 'getadminusers';
  getAdminUsers(limit, page).then(
    response => dispatch(successResponse(response, moredata)),
    err => dispatch(errorResponse(err)),
  );
};

export const addUser = data => (dispatch) => {
  dispatch(showActionLoading());
  addAdminUser(data).then(
    response => dispatch(successResponse(response, 'saveuser')),
    err => dispatch(errorResponse(err)),
  );
};

export const updateUser = (id, data) => (dispatch) => {
  dispatch(showActionLoading());
  updateAdminUser(id, data).then(
    response => dispatch(successResponse(response, 'updateuser', id)),
    err => dispatch(errorResponse(err)),
  );
};

export const deleteUser = id => (dispatch) => {
  dispatch(showActionLoading());
  deleteAdminUser(id).then(
    response => dispatch(successResponse(response, 'deleteuser', id)),
    err => dispatch(errorResponse(err)),
  );
};

export const turnOnClient = (id, data) => (dispatch) => {
  dispatch(showActionLoading());
  activateClient(id, data).then(
    response => dispatch(successResponse(response, 'activateclient')),
    err => dispatch(errorResponse(err)),
  );
};

export const turnOffClient = id => (dispatch) => {
  dispatch(showActionLoading());
  deactivateClient(id).then(
    response => dispatch(successResponse(response, 'deactivateclient', id)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchClientDetails = id => (dispatch) => {
  dispatch(showActionLoading());
  getClientDetails(id).then(
    response => dispatch(successResponse(response, 'clientdetails')),
    err => dispatch(errorResponse(err)),
  );
};

export const updateClientUserPassword = (id, data) => (dispatch) => {
  dispatch(showActionLoading());
  updateClientPassword(id, data).then(
    response => dispatch(successResponse(response, 'updateuserpassword')),
    err => dispatch(errorResponse(err)),
  );
};

export const apiRefresh = () => (dispatch) => {
  refreshApi().then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err)),
  );
};
