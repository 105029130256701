export const SET_CALENDAR_EVENTS_DATA = 'reports/set-calendar-events-data';
export const SET_SINGLE_EVENT_DATA = 'reports/set-single-event-data';
export const SET_ALL_CLIENTS_DATA = 'reports/set-all-clients-data';
export const SET_MORE_CLIENTS_DATA = 'reports/set-more-clients-data';
export const SET_CLIENT_DETAILS_DATA = 'reports/set-client-details-data';
export const SET_ADMIN_USERS_DATA = 'reports/set-admin-users-data';
export const SET_MORE_ADMIN_USERS_DATA = 'reports/set-more-admin-users-data';
export const SET_SAVE_ADMIN_USERS_DATA = 'reports/set-save-admin-users-data';
export const SET_UPDATE_ADMIN_USERS_DATA = 'reports/set-update-admin-users-data';
export const SET_DELETE_ADMIN_USERS_DATA = 'reports/set-delete-admin-users-data';
export const SET_ACTIVATE_CLIENT_DATA = 'reports/set-activate-client-data';
export const SET_DEACTIVATE_CLIENT_DATA = 'reports/set-deactivate-client-data';
